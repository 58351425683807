import './css/styles.css';
import "bootstrap/dist/js/bootstrap.js";
import { Helmet } from 'react-helmet';
import Navigation from './components/Navigation';
import MasterHeader from './components/MasterHeader';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';


const car_fixing = 'https://storage.googleapis.com/porto-vistoria-app-bucket/img/car-fixing.jpg';
const car_fixing_suspended = 'https://storage.googleapis.com/porto-vistoria-app-bucket/img/car-fixing-suspended.jpg';
const car_inspection = 'https://storage.googleapis.com/porto-vistoria-app-bucket/img/car-inspection.jpg';
const car_suspended = 'https://storage.googleapis.com/porto-vistoria-app-bucket/img/car-suspended.jpg';
const car_transparent = 'https://storage.googleapis.com/porto-vistoria-app-bucket/img/car.png';

function App() {
  return (
    <div className="App">

      <header className="App-header">
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        <title>Grayscale - Start Bootstrap Theme</title>
        <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
        <Helmet>
          {/* <!-- Font Awesome icons (free version)--> */}
          <script src="https://use.fontawesome.com/releases/v6.3.0/js/all.js"></script>
        </Helmet>
        {/* <!-- Google fonts--> */}
        <link href="https://fonts.googleapis.com/css?family=Varela+Round" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i" rel="stylesheet" />
        {/* <!-- Core theme CSS (includes Bootstrap)--> */}
        <link href="css/styles.css" rel="stylesheet" />
      </header>

      <body id='page-top'>
        <Navigation name={'Porto Vistoria'} items={[
          {name:'A Empresa', href:'#about_session'},
          {name:'Serviços', href:'#services_session'},
          {name:'Contato', href:'#contact_session'},
        ]}/>
        <MasterHeader
          title='Porto Vistoria' 
          subtitle='Sua Compra e Venda de Veículos com Segurança e Confiabilidade. (11) 98468-3362' 
        />
        <About id='about_session' header='Porto Vistoria' text='
        A Porto Vistoria é uma empresa credenciada pelo Detran, dedicada a fornecer vistorias veiculares de alta qualidade. 
        Nossa missão é garantir segurança e tranquilidade em todas as etapas da compra e venda de veículos. 
        Com longa experiência no mercado automotivo, nossa equipe altamente qualificada realiza inspeções
        detalhadas para garantir que todos os aspectos do veículo estejam em conformidade com os padrões mais rigorosos. 
        Nosso compromisso com a excelência, transparência e atendimento ao cliente é o que nos diferencia. Quando você escolhe a Porto Vistoria, 
        está escolhendo confiança e paz de espírito em cada transação automotiva.
        ' image={car_transparent}/>
        <Services 
          id = 'services_session'
          featuredService = {{
            serviceName:'Vistoria ECV (Empresas Credenciadas de Vistoria)', 
            description:`Somos uma ECV de confiança, autorizada pelo Detran, que oferece vistorias precisas e detalhadas. Garantimos a 
            conformidade do veículo com as normas e regulamentos vigentes, contribuindo para transações transparentes e seguras.`, 
            image:car_fixing
          }}
          oneRowService={{
            serviceName:'Laudo Cautelar',
            description:`Nosso serviço de laudo cautelar é essencial para quem deseja garantir a procedência e a integridade 
            de um veículo usado. Realizamos uma análise minuciosa que verifica histórico, documentação e possíveis irregularidades,
            proporcionando segurança na compra ou venda.'`,
            image:car_inspection
          }}
          twoRowService={{
            serviceName:'Vistoria Detran',
            description:`Nossa vistoria Detran é realizada em conformidade com os padrões estabelecidos pelo órgão competente. 
            Verificamos a documentação e as condições do veículo, certificando que ele atenda aos requisitos legais para circulação.`,
            image: car_suspended
          }}
          threRowService={{
            serviceName:'Vistoria Prévia',
            description:`Para seguradoras e instituições financeiras, oferecemos serviços de vistoria prévia, 
            assegurando a avaliação precisa do veículo segurado ou financiado. Nossos relatórios contribuem para uma gestão de risco eficiente.`,
            image: car_fixing_suspended
          }}
        />

        <Contact id='contact_session' 
          address='João Alfredo, 400 - Santo Amaro, São Paulo - SP, 04747-001, Brasil' 
          email='contato@portovistoria.com' 
          phone='11 98468-3362'   
        />

      </body>

    </div>
  );
}

export default App;
