import React from "react";
// import DemoImage1 from "../assets/img/demo-image-01.jpg"


export interface ServiceProp {
    serviceName: string
    description: string
    image: string
}


interface ServicesProp {
    id: string
    featuredService: ServiceProp
    oneRowService: ServiceProp
    twoRowService: ServiceProp
    threRowService: ServiceProp
}


const FeaturedService: React.FC<ServiceProp> = ({  serviceName, description, image }) => {
    return (
        <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
            <div className="col-xl-8 col-lg-7"><img className="img-fluid mb-3 mb-lg-0" src={image} alt="..." /></div>
            <div className="col-xl-4 col-lg-5">
                <div className="featured-text text-center text-lg-left">
                    <h4>{serviceName}</h4>
                    <p className="text-black-50 mb-0">{description}</p>
                </div>
            </div>
        </div>
    );
};

const OneRowService: React.FC<ServiceProp> = ({ serviceName, description, image }) => {
    return (
        <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div className="col-lg-6"><img className="img-fluid" src={image} alt="..." /></div>
            <div className="col-lg-6">
                <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                        <div className="project-text w-100 my-auto text-center text-lg-left">
                            <h4 className="text-white">{serviceName}</h4>
                            <p className="mb-0 text-white-50">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



const TwoRowService: React.FC<ServiceProp> = ({ serviceName, description, image }) => {
    return (
        <div className="row gx-0 justify-content-center">
            <div className="col-lg-6"><img className="img-fluid" src={image} alt="..." /></div>
            <div className="col-lg-6 order-lg-first">
                <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                        <div className="project-text w-100 my-auto text-center text-lg-right">
                            <h4 className="text-white">{serviceName}</h4>
                            <p className="mb-0 text-white-50">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



const Services: React.FC<ServicesProp> = ({ id, featuredService, oneRowService, twoRowService, threRowService }) => {
    return (
        <section className="projects-section bg-light" id={id}>
            <div className="container px-4 px-lg-5">
                {/* <!-- Featured Project Row--> */}
                <FeaturedService {...featuredService} />
                {/* <!-- Project One Row--> */}
                <OneRowService {...oneRowService}/>
                {/* <!-- Project Two Row--> */}
                <TwoRowService {...twoRowService}/>
                <OneRowService {...threRowService}/>
            </div>
        </section>
    );
};

export default Services;