
import React  from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login  from '../features/login/Login';

interface link{
  name: string
  href: string
}
interface NavigationProps {
  name: string
  items: link[]
}

const Navigation: React.FC<NavigationProps> = ({name,items}) =>{
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div className="container px-4 px-lg-5">
      <a className="navbar-brand" href="#page-top">{name}</a>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i className="fas fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ms-auto">
          {
            items.map((item,i)=>{
              return <li className="nav-item"><a className="nav-link" href={item.href}>{item.name}</a></li>
            })
          }
          {/* <Login/> */}
        </ul>
      </div>
    </div>
  </nav>
  );
}

export default Navigation;